
import * as bunnyRuntime$_4a_45LSI66tN_45561x1XFMIYhST9tYd1l4g_Pltx1JjvOY from '/vercel/path0/node_modules/@nuxt/image/dist/runtime/providers/bunny'
import * as sanityRuntime$lbW84Q9H5ArlQAQZO6ZnpqD8O7AshtAGS6IzOAD1rIs from '/vercel/path0/node_modules/@nuxt/image/dist/runtime/providers/sanity'
import * as vercelRuntime$DURA3w36tVJ0DkH56wm70Nz4S7WxJbsSJXOSwClr9RY from '/vercel/path0/node_modules/@nuxt/image/dist/runtime/providers/vercel'

export const imageOptions = {
  "screens": {
    "35": 35,
    "70": 70,
    "80": 80,
    "140": 140,
    "160": 160,
    "179": 179,
    "280": 280,
    "320": 320,
    "358": 358,
    "402": 402,
    "416": 416,
    "500": 500,
    "512": 512,
    "565": 565,
    "576": 576,
    "640": 640,
    "648": 648,
    "704": 704,
    "768": 768,
    "798": 798,
    "804": 804,
    "832": 832,
    "950": 950,
    "960": 960,
    "1000": 1000,
    "1024": 1024,
    "1130": 1130,
    "1152": 1152,
    "1176": 1176,
    "1280": 1280,
    "1296": 1296,
    "1408": 1408,
    "1472": 1472,
    "1536": 1536,
    "1596": 1596,
    "1900": 1900,
    "1920": 1920,
    "2000": 2000,
    "2008": 2008,
    "2048": 2048,
    "2352": 2352,
    "2560": 2560,
    "2944": 2944,
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "default": {
      "quality": 80,
      "modifiers": {
        "format": "auto"
      }
    }
  },
  "provider": "vercel",
  "domains": [
    "cdn.sanity.io",
    "www.butlersherborn.co.uk",
    "lh3.googleusercontent.com",
    "img.youtube.com",
    "i.vimeocdn.com"
  ],
  "alias": {
    "/youtube": "https://img.youtube.com",
    "/vimeo": "https://i.vimeocdn.com"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "avif",
    "webp",
    "png",
    "webp"
  ],
  "quality": 80
}

imageOptions.providers = {
  ['bunny']: { provider: bunnyRuntime$_4a_45LSI66tN_45561x1XFMIYhST9tYd1l4g_Pltx1JjvOY, defaults: {"baseURL":"https://butlersherborn-alto.b-cdn.net"} },
  ['sanity']: { provider: sanityRuntime$lbW84Q9H5ArlQAQZO6ZnpqD8O7AshtAGS6IzOAD1rIs, defaults: {"projectId":"js7r23bq"} },
  ['vercel']: { provider: vercelRuntime$DURA3w36tVJ0DkH56wm70Nz4S7WxJbsSJXOSwClr9RY, defaults: {} }
}
        