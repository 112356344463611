
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexG_WfXOzFIdmY7nC2wskK9_5LGE8hGwIJyZEyCaXLOuMMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexbKVC1XMPW2oHMGrir70dCL9zjVIP1bUFPZUOyKLytTAMeta } from "/vercel/path0/pages/fees/index.vue?macro=true";
import { default as index8TnTrG1RLdezOSU25M4mZcfRoygosPx29K_45pj23ghrsMeta } from "/vercel/path0/pages/about/index.vue?macro=true";
import { default as _91slug_93Oh6C1O4IgPkGBgToJIpLZbxEZqZpKkPOZotCVB5EZNkMeta } from "/vercel/path0/pages/fees/[slug].vue?macro=true";
import { default as indexQs2ZaJbJ_IFQ3gUkI0E40mbpgvdATKwVhx_45mya_2pJwMeta } from "/vercel/path0/pages/rural/index.vue?macro=true";
import { default as indexuV7aZLbiImvzML5sPIXtfDjQjlMD8sG497TACIvDkEgMeta } from "/vercel/path0/pages/browse/index.vue?macro=true";
import { default as tableoKx_45eIGnABj3Pjho9f938yT4ClKb8DwyxYJh9UNky6sMeta } from "/vercel/path0/pages/browse/table.vue?macro=true";
import { default as _91slug_931r7_VY_45oezjYF5ihAFWiYIpJ_4599u6G05qtfaBKuUjXAMeta } from "/vercel/path0/pages/rural/[slug].vue?macro=true";
import { default as indexTkFtT1OXARLa9LfgaRkqE19_45aZw9CkVMwWjVPxVJp2UMeta } from "/vercel/path0/pages/search/index.vue?macro=true";
import { default as indexyo0ZR8uC8STUuVro4_xvhQSFdQsc23lU_lQ4YMD4yPsMeta } from "/vercel/path0/pages/careers/index.vue?macro=true";
import { default as indexdW670_4563LDuILksGHTznS0mHyGjoe6Ar6P3C6d_45G8r0Meta } from "/vercel/path0/pages/contact/index.vue?macro=true";
import { default as _91slug_93KqBFxOJAyUbDZPkuo7NQ_45mM_45cJRY28FasIjAe2Q0daYMeta } from "/vercel/path0/pages/people/[slug].vue?macro=true";
import { default as index0f8JRB9cR_4501TW1jzlinuL4Z4mObPIqkaQTdKquio18Meta } from "/vercel/path0/pages/articles/index.vue?macro=true";
import { default as _91slug_93or0xZ3T0mYGVsykyoYaP_E_nRf_TN5GOjnKzkyiUQw8Meta } from "/vercel/path0/pages/careers/[slug].vue?macro=true";
import { default as indexHUd1txdmrOKl6_45Vuo_Pm3X5qAbMwK7_g5EFsAhbsE_MMeta } from "/vercel/path0/pages/policies/index.vue?macro=true";
import { default as _91slug_933okxONYrOqvv1SuPlUWNMR0KQofkth8FTJlmwJHvJ0sMeta } from "/vercel/path0/pages/articles/[slug].vue?macro=true";
import { default as _91slug_936dPUAljRpzTLu7VSgzKFqRNhVkSgmm_pRpoBjwl2nzwMeta } from "/vercel/path0/pages/policies/[slug].vue?macro=true";
import { default as _91slug_93Yc7M_453_iZXAbhtoZox70fT_45V3JHDx7Uh6ZdjZTWo3aMMeta } from "/vercel/path0/pages/property/[slug].vue?macro=true";
import { default as _91slug_93fwOOePbi3fEpNdkQX_CkvE9cQUwVjfBOEkKxWcvfReIMeta } from "/vercel/path0/pages/thank-you/[slug].vue?macro=true";
import { default as favouritesdh6kU64vAc5RwkrzPrpDc5DNHg0Fb48ZQHm29s13wBgMeta } from "/vercel/path0/pages/browse/favourites.vue?macro=true";
import { default as index48Q_45EZJo9kxzbkGoY1EfiXDFHiuumY5FxZ2nL9VWwSsMeta } from "/vercel/path0/pages/mailing-list/index.vue?macro=true";
import { default as _91slug_939lHvzwm_45_45fcRz2ZwfEuIKjWrSIR9qKcrhL_45ipxqD58IMeta } from "/vercel/path0/pages/estate-agent/[slug].vue?macro=true";
import { default as why_45butler_45sherbornVC520RQnCEa9sbTMFR5HDTrzpJpbD0LgQUZUi0MAeKQMeta } from "/vercel/path0/pages/why-butler-sherborn.vue?macro=true";
import { default as _91page_93Lsfja_Bo4_45sQ0bcGB_458MaN_45MAdbpQIhLuJPk_d3H7bIMeta } from "/vercel/path0/pages/articles/page/[page].vue?macro=true";
import { default as indexfVlKb0SPRFtKtwcSk8W3eV4cuS9cLTzO4Tn62GP3T_45YMeta } from "/vercel/path0/pages/success-stories/index.vue?macro=true";
import { default as recently_45viewedi_jK1bJEOuKD1mgVnsaBLddDPnjGtgzPdN8dSTKrMh8Meta } from "/vercel/path0/pages/browse/recently-viewed.vue?macro=true";
import { default as _91slug_93OrjuhqkaZtrL37E284Q9nMuWcKazlUhOKrltAG6WIUwMeta } from "/vercel/path0/pages/success-stories/[slug].vue?macro=true";
import { default as indexD_XMMdfFNzgiAanaWRnrk7d7jB98C9I3lAjjp2g3gSAMeta } from "/vercel/path0/pages/digital-valuation/index.vue?macro=true";
import { default as _91slug_93FfW7F2QHLf6dlML_5D4_lxerWch3dxoTYGL6s5eo1JIMeta } from "/vercel/path0/pages/property-services/[slug].vue?macro=true";
import { default as component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "fees",
    path: "/fees",
    component: () => import("/vercel/path0/pages/fees/index.vue")
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/vercel/path0/pages/about/index.vue")
  },
  {
    name: "fees-slug",
    path: "/fees/:slug()",
    component: () => import("/vercel/path0/pages/fees/[slug].vue")
  },
  {
    name: "rural",
    path: "/rural",
    meta: indexQs2ZaJbJ_IFQ3gUkI0E40mbpgvdATKwVhx_45mya_2pJwMeta || {},
    component: () => import("/vercel/path0/pages/rural/index.vue")
  },
  {
    name: "browse",
    path: "/browse",
    component: () => import("/vercel/path0/pages/browse/index.vue")
  },
  {
    name: "browse-table",
    path: "/browse/table",
    component: () => import("/vercel/path0/pages/browse/table.vue")
  },
  {
    name: "rural-slug",
    path: "/rural/:slug()",
    meta: _91slug_931r7_VY_45oezjYF5ihAFWiYIpJ_4599u6G05qtfaBKuUjXAMeta || {},
    component: () => import("/vercel/path0/pages/rural/[slug].vue")
  },
  {
    name: "search",
    path: "/search",
    meta: indexTkFtT1OXARLa9LfgaRkqE19_45aZw9CkVMwWjVPxVJp2UMeta || {},
    component: () => import("/vercel/path0/pages/search/index.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/vercel/path0/pages/careers/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact/index.vue")
  },
  {
    name: "people-slug",
    path: "/people/:slug()",
    meta: _91slug_93KqBFxOJAyUbDZPkuo7NQ_45mM_45cJRY28FasIjAe2Q0daYMeta || {},
    component: () => import("/vercel/path0/pages/people/[slug].vue")
  },
  {
    name: "articles",
    path: "/articles",
    meta: index0f8JRB9cR_4501TW1jzlinuL4Z4mObPIqkaQTdKquio18Meta || {},
    component: () => import("/vercel/path0/pages/articles/index.vue")
  },
  {
    name: "careers-slug",
    path: "/careers/:slug()",
    component: () => import("/vercel/path0/pages/careers/[slug].vue")
  },
  {
    name: "policies",
    path: "/policies",
    component: () => import("/vercel/path0/pages/policies/index.vue")
  },
  {
    name: "articles-slug",
    path: "/articles/:slug()",
    meta: _91slug_933okxONYrOqvv1SuPlUWNMR0KQofkth8FTJlmwJHvJ0sMeta || {},
    component: () => import("/vercel/path0/pages/articles/[slug].vue")
  },
  {
    name: "policies-slug",
    path: "/policies/:slug()",
    component: () => import("/vercel/path0/pages/policies/[slug].vue")
  },
  {
    name: "property-slug",
    path: "/property/:slug()",
    component: () => import("/vercel/path0/pages/property/[slug].vue")
  },
  {
    name: "thank-you-slug",
    path: "/thank-you/:slug()",
    component: () => import("/vercel/path0/pages/thank-you/[slug].vue")
  },
  {
    name: "browse-favourites",
    path: "/browse/favourites",
    component: () => import("/vercel/path0/pages/browse/favourites.vue")
  },
  {
    name: "mailing-list",
    path: "/mailing-list",
    component: () => import("/vercel/path0/pages/mailing-list/index.vue")
  },
  {
    name: "estate-agent-slug",
    path: "/estate-agent/:slug()",
    component: () => import("/vercel/path0/pages/estate-agent/[slug].vue")
  },
  {
    name: "why-butler-sherborn",
    path: "/why-butler-sherborn",
    component: () => import("/vercel/path0/pages/why-butler-sherborn.vue")
  },
  {
    name: "articles-page-page",
    path: "/articles/page/:page()",
    meta: _91page_93Lsfja_Bo4_45sQ0bcGB_458MaN_45MAdbpQIhLuJPk_d3H7bIMeta || {},
    component: () => import("/vercel/path0/pages/articles/page/[page].vue")
  },
  {
    name: "success-stories",
    path: "/success-stories",
    component: () => import("/vercel/path0/pages/success-stories/index.vue")
  },
  {
    name: "browse-recently-viewed",
    path: "/browse/recently-viewed",
    component: () => import("/vercel/path0/pages/browse/recently-viewed.vue")
  },
  {
    name: "success-stories-slug",
    path: "/success-stories/:slug()",
    component: () => import("/vercel/path0/pages/success-stories/[slug].vue")
  },
  {
    name: "digital-valuation",
    path: "/digital-valuation",
    component: () => import("/vercel/path0/pages/digital-valuation/index.vue")
  },
  {
    name: "property-services-slug",
    path: "/property-services/:slug()",
    component: () => import("/vercel/path0/pages/property-services/[slug].vue")
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/articles/page",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/people",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/properties",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/estate-agent",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/property-blog/:pathMatch(.*)",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/browse/latest-properties-sale",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  }
]